import { Injectable } from '@angular/core';

// BS service providers
import { WsmanagerService } from './wsmanager.service';
import { SettingsmanagerService } from './settingsmanager.service';

@Injectable({
  providedIn: 'root'
})
export class MapmanagerService {

  device_positions = null;
  zones = null;
  city_data = null;
  hubs = null;

  constructor(private settingsManager: SettingsmanagerService, private wsManager: WsmanagerService) {
    console.log('mapmanager constructor');
  }

  /**
   *  Fetches all the vehicles that match:
   *    - The city selected by the user
   *    - The status of the vehicle OR the id of the vehicle
   *
   * @param cityId: The id of the city
   * @param vehicleStatus: The array of allowed status
   * @param showVehiclesId: The list of the vehicles to show regardless of status
   */
  fetchDevicesByCityId(cityId: number, vehicleStatus: Array<Number> = [], showVehiclesIds= []): Promise<any> {
    // console.log('getAllDevicesPositions', cityId, vehicleStatus, showVehiclesIds);
    const statusArray: Array<Number> = vehicleStatus;
    return new Promise ((resolve, reject) => {
      this.wsManager.fetchDevicesByCityId(cityId).subscribe(data => {
        // console.log('@@@data:', data);
        if (vehicleStatus.length > 0) {
          this.device_positions = data.filter(function(item) {
            return statusArray.includes(item.stateId) || (showVehiclesIds.includes(Number(item.id)));
          });
        } else {
          this.device_positions = data;
        }
        // console.log('@@@data-typeof:', typeof(this.device_positions));
        resolve(this.device_positions);
      }, err => {
        console.log('ERR-POSITIONS1:', err);
        reject(err);
      });
    });
  }

  /**
   *  Fetches all the vehicles that match:
   *    - The status of the vehicle OR the id of the vehicle
   *
   * @param vehicleStatus: The array of allowed status
   * @param showVehiclesId: The list of the vehicles to show regardless of status
   */
  fetchAllDevices(vehicleStatus: Array<Number> = [], showVehiclesIds= []): Promise<any> {
    // console.log('getAllDevicesPositions', vehicleStatus, showVehiclesIds);
    const statusArray: Array<Number> = vehicleStatus;
    return new Promise ((resolve, reject) => {
      this.wsManager.fetchAllDevices().subscribe(data => {
        // console.log('@@@data:', data);
        if (vehicleStatus.length > 0) {
          this.device_positions = data.filter(function(item) {
            return statusArray.includes(item.stateId) || (showVehiclesIds.includes(Number(item.id)));
          });
        } else {
          this.device_positions = data;
        }
        // console.log('@@@data-typeof:', typeof(this.device_positions));
        resolve(this.device_positions);
      }, err => {
        console.log('ERR-POSITIONS2:', err);
        reject(err);
      });
    });
  }

  /**
   *  Fetches all the vehicles that match filters specified in callback
   *
   * @param objFilters: An object with specified filters
  */
  fetchAllDevicesWithFilters(objFilters: any): Promise<any> {
    return new Promise ((resolve, reject) => {
      this.wsManager.fetchAllDevicesWithFilters(objFilters).subscribe(data => {
        this.device_positions = data;
        resolve(this.device_positions);
      }, err => {
        console.log('ERR-POSITIONS2:', err);
        reject(err);
      });
    });
  }

  /**
   *  Fetches all the zones that match:
   *    - The city selected by the user
   *
   * @param cityId: The id of the city
   */
  fetchZonesByCityId(cityId: number): Promise<any> {
    console.log('map manager fetchZonesByCityId', cityId);
    return new Promise ((resolve, reject) => {
      this.wsManager.fetchZonesByCityId(cityId).subscribe(data => {
          this.zones = data;
        resolve(this.zones);
      }, err => {
        console.log('ERR-POSITIONS4:', err);
        reject(err);
      });
    });
  }

  /**
   *  Fetches all the zones of the system
   *
   */
  fetchAllZones(cityId: number): Promise<any> {
    console.log('map manager fetchZonesByCityId', cityId);
    return new Promise ((resolve, reject) => {
      this.wsManager.fetchAllZones().subscribe(data => {
        this.zones = data;
        resolve(this.zones);
      }, err => {
        console.log('ERR-POSITIONS5:', err);
        reject(err);
      });
    });
  }

  /**
   * Fetches the data of a city
   *
   * @param cityId: the id of the city.
   */
  fetchCityData(cityId: number): Promise<any> {
    console.log('map manager fetchCityData', cityId);
    return new Promise ((resolve, reject) => {
      this.wsManager.fetchCityData(cityId).subscribe(data => {
        this.city_data = data;
        resolve(this.city_data);
      }, err => {
        console.log('ERR-CITY-DATA:', err);
        reject(err);
      });
    });
  }

  /**
   * Fetches all the cities
   *
   */
  fetchCitiesData(): Promise<any> {
    return new Promise ((resolve, reject) => {
      this.wsManager.fetchCitiesData().subscribe(data => {
        console.log(data);
        this.city_data = data;
        resolve(this.city_data);
      }, err => {
        console.log('ERR-CITY-DATA:', err);
        reject(err);
      });
    });
  }



}
