import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DocumentRegistrationRequests, DocumentStatus, DocumentTypeItem } from '../classes';
import {SettingsmanagerService} from './settingsmanager.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  private _url: string;

  constructor(
    private httpClient: HttpClient,
    private settingsManager: SettingsmanagerService
  ) {
    this._url =  this.settingsManager.getBaseWsUrl();
  }

  getWsHeader(content_type = 'application/json') {
    const tempToken = this.getToken();
    // console.log('@@@@@@@ TOKEN1:', tempToken);
    const headers = {
      'Content-Type': content_type,
      'Accept': 'plain/text',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT',
      'Access-Control-Allow-Headers': 'Authorization, Origin, Content-Type, X-CSRF-Token',
      'Cache-control': 'no-cache',
      'Expires': '0',
      'Pragma': 'no-cache',
      'auth': tempToken
    };
    // console.log('@@@HEADERS:', headers);
    return new HttpHeaders(headers);
  }

  getToken() {
    return this.settingsManager.getToken();
  }

  documentRegistrationGetTypes(): Observable<DocumentTypeItem[]> {
    const command = 'document/type/all';
    const commandUrl = [this._url, command].join('/');
    return this.httpClient.get<DocumentTypeItem[]>(commandUrl,
      {headers: this.getWsHeader(), responseType: 'json'}).pipe(
        map(results => results.map((result) => new DocumentTypeItem(result)))
      );
  }

  documentRegistrationGetData(documentDataId: number): Observable<DocumentRegistrationRequests> {
    const command = `document/data/${documentDataId}`;
    const commandUrl = [this._url, command].join('/');
    return this.httpClient.get<DocumentRegistrationRequests>(commandUrl,
      {headers: this.getWsHeader(), responseType: 'json'}).pipe(
        map(result =>  new DocumentRegistrationRequests(result)));
  }

  documentRegistrationGetAll(loadAll = false): Observable<DocumentRegistrationRequests[]> {
    const command = 'document/data';
    const commandUrl = [this._url, command].join('/');
    let objParams: any = null;
    if (!loadAll) {
      objParams = { rows: 2000 };
    }
    return this.httpClient.get<DocumentRegistrationRequests[]>(commandUrl,
      {headers: this.getWsHeader(), responseType: 'json', params: objParams}).pipe(
        map(results => results.map((result) => new DocumentRegistrationRequests(result)))
      );
  }

  
  deletePhotoInRequest(photoId: number): Observable<void> {
    const command = `document/image/remove/${photoId}`;
    const commandUrl = [this._url, command].join('/');
    return this.httpClient.delete<void>(commandUrl,
      {headers: this.getWsHeader(), responseType: 'json'});
  }

  documentRegistrationDownloadImage(documentDataId: number, photoOrientation: string): Observable<{ img: Blob | null }> {
    const command = 'document/image/download';
    const commandUrl = [this._url, command].join('/');
    const commandBody =  {
      documentDataId: documentDataId,
      photoOrientation: photoOrientation
    };
    return this.httpClient.post<Blob>(commandUrl,
      commandBody,
      {headers: this.getWsHeader(), responseType: 'blob' as 'json'}).pipe(
        map((x) => {return { img: x };
        })
      );
  }

  documentRegistrationEnable(idRequest: number): Observable<void> {
    const command = `driver-data/accept/${idRequest}`;
    const commandUrl = [this._url, command].join('/');
    return this.httpClient.post<void>(commandUrl, null,
      {headers: this.getWsHeader(), responseType: 'json'}
    );
  }

  documentRegistrationDisable(idRequest: number): Observable<void> {
    const command = `driver-data/reject/${idRequest}`;
    const commandUrl = [this._url, command].join('/');
    return this.httpClient.post<void>(commandUrl, null,
      {headers: this.getWsHeader(), responseType: 'json'}
    );
  }

  documentRegistrationStatus(documentDataId: number, status: DocumentStatus): Observable<void> {
    const command = 'document/data/status';
    const commandUrl = [this._url, command].join('/');
    return this.httpClient.post<void>(commandUrl, {id: documentDataId,status: status},
      {headers: this.getWsHeader(), responseType: 'json'}
    );
  }

  documentRegistrationShowImage(idRequest: number, type: string): Observable<Blob> {
    const command = `driver-data/${idRequest}/image/${type}`;
    const commandUrl = [this._url, command].join('/');
    return this.httpClient.get<Blob>(commandUrl,
      {headers: this.getWsHeader(), responseType: 'blob' as 'json'}
    );
  }
}
