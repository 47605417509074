import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MapmanagerService } from '../services/mapmanager.service';
import { ToastController  } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {

  constructor(
    private mapManager: MapmanagerService,
    private toastController: ToastController,
    private translate: TranslateService
  ) { }

  // Confronta nome device fornito e restituisce il corretto se esiste
  async getCorrectDevice(p_deviceName) {
    if ( this.mapManager.device_positions === null ) {
      try {
        await this.mapManager.fetchAllDevices();
      } catch {
        return null;
      }
    }

    p_deviceName = p_deviceName.trim();

    const vehicleName = p_deviceName;
    console.log('showVehicleLogs - cerco veicolo n.', vehicleName, this.mapManager.device_positions);
    const vehicles = this.mapManager.device_positions
      .filter(function(item) {
        const nameCleaned = item.name.trim().toLocaleLowerCase();
        return nameCleaned === vehicleName.trim().toLocaleLowerCase()
      });

      if (vehicles.length) {
        return vehicles;
      } else {
        return null;
      }
  }
}
