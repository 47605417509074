import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import * as HttpStatusCodes from 'http-status-codes';
import { GrantPlatform, GrantPlatformType } from '../classes';
import {SettingsmanagerService} from './settingsmanager.service';

@Injectable({
  providedIn: 'root'
})
export class GrantService {

  private mapGrants: Map<GrantPlatformType, boolean>;
  private _url: string;

  constructor(
    private _http: HttpClient,
    private settingsManager: SettingsmanagerService,
    // @Inject(BACKEND_URL) private _url: string
  ) {
    this.mapGrants = new Map<GrantPlatformType, boolean>();
    this._url =  this.settingsManager.getBaseWsUrl();
  }

  getAllPermissions(): Observable<Map<GrantPlatformType, boolean>> {
    if (this.mapGrants.keys.length > 0) {
      return of(this.mapGrants);
    }
    return this._http.get(`${this._url}/grantplatform`).pipe(
      map((list:Array<GrantPlatform>) => {
        list.forEach(x => { this.mapGrants[x.name] = x.enabled; });
        return this.mapGrants;
      }),
    );
  }

  checkPermission(perm: GrantPlatformType): Observable<boolean> {
    if (perm in this.mapGrants) {
      return of(this.mapGrants[perm]);
    }
    return this._http.get<any>(`${this._url}/grantplatform/${perm}`).pipe(
      map(() => {
        this.mapGrants[perm] = true;
        return true;
      }),
      catchError((err: HttpErrorResponse) => {
        if (err.status === HttpStatusCodes.FORBIDDEN) {
          this.mapGrants[perm] = false;
        }
        return of(false);
      })
    );
  }
}
