import { AbstractControl, ValidatorFn } from '@angular/forms';
import * as libphonenumber from 'google-libphonenumber';

export enum phoneValidatorMode {
  MOBILE = 'MOBILE',
  FIXED = 'FIXED',
  ALL = 'ALL'
}

function validateFixedItalianPhone(phoneNumber: string): boolean {
  // formati accettati:
  // - 0xxx xxxxxx
  // - 0x xxxx xxxx
  // - 0x XXXXXXXX
  // - 0xx xxxxxxx
  let phoneFormatted: string;

  // check
  const parts = phoneNumber.split(/\s/);
  if (parts.length <= 1) {
    return false;
  } else if (parts.length > 2) {
    phoneFormatted = `${parts[0]} ${phoneNumber.split(/\s/).slice(1).join('')}`;
  } else {
    phoneFormatted = `${parts[0]} ${parts[1]}`;
  }
  if (!/^0\d{1,3}\s\d+$/.test(phoneFormatted)) {
    return false;
  }

  // check su numero associato a distretto
  const pref = phoneFormatted.split(/\s/)[0].substring(1);
  const digits = phoneFormatted.split(/\s/)[1];

  let min: number;
  let max: number;

  switch (pref.length) {
    case 1:
      min = 7;
      max = 8;
      break;
    case 2:
      min = 6;
      max = 7;
      break;
    case 3:
      min = 5;
      max = 6;
      break;
    default:
      return false;
  }
  return digits.length <= max && digits.length >= min;
}



export class PhoneValidator {

  // Inspired on: https://github.com/yuyang041060120/ng2-validation/blob/master/src/equal-to/validator.ts
  static validCountryPhone(countryControl: AbstractControl, mode: phoneValidatorMode): ValidatorFn {
    let subscribe = false;

    return (phoneControl: AbstractControl): {[key: string]: boolean} => {
      if (!subscribe) {
        subscribe = true;
        countryControl.valueChanges.subscribe(() => {
          phoneControl.updateValueAndValidity();
        });
      }

      if (phoneControl.value !== '') {
        try {
          const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
          const phoneNumber = ('' + phoneControl.value + '');
          let isValidNumber: boolean;

          if (!/^\d+$/.test(phoneNumber)) {
            isValidNumber = false;
          } else {
            const region = countryControl.value.iso;
            const number = phoneUtil.parse(phoneNumber, region);

            switch (mode) {
              case phoneValidatorMode.MOBILE:
                isValidNumber = phoneUtil.isValidNumber(number) && phoneUtil.getNumberType(number) === libphonenumber.PhoneNumberType.MOBILE;
                break;
              case phoneValidatorMode.FIXED:
                isValidNumber = phoneUtil.isValidNumber(number) && phoneUtil.getNumberType(number) === libphonenumber.PhoneNumberType.FIXED_LINE;
                if (isValidNumber && number.getCountryCode() === 39) {
                  isValidNumber = validateFixedItalianPhone(phoneUtil.format(number, libphonenumber.PhoneNumberFormat.NATIONAL));
                }
                break;
              case phoneValidatorMode.ALL:
                isValidNumber = phoneUtil.isValidNumber(number);
                if (isValidNumber
                    && phoneUtil.getNumberType(number) === libphonenumber.PhoneNumberType.FIXED_LINE
                    && number.getCountryCode() === 39
                ) {
                  isValidNumber = validateFixedItalianPhone(phoneUtil.format(number, libphonenumber.PhoneNumberFormat.NATIONAL));
                }
                break;
            }
          }

          if (isValidNumber) {
            return null;
          }
        } catch (e) {
          // console.log(e);
          return {
            validCountryPhone: true
          };
        }

        return {
          validCountryPhone: true
        };
      } else {
        return null;
      }
    };
  }
}
