import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class PagetitlerService {

  private printedTitle: string = this.translate.instant('Map');

  constructor(
    public router: Router,
    private translate: TranslateService,
    private titleService: Title
  ) {}

  // Setta il titolo della Pagina
  setTitle(title: string) {
    this.titleService.setTitle(title);
    this.printedTitle = title;
  }

  // Setta il titolo secondario della Pagina
  setSubTitle(subTitle: string) {
    this.titleService.setTitle(this.printedTitle + ' - ' + subTitle) 
  }
}
